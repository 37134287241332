import React from "react";
import { Layout, Menu, Breadcrumb, Button, Row, Col, Avatar } from "antd";
import {
  DesktopOutlined,
  ReadOutlined,
  UserOutlined,
  LogoutOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { useAuth } from "../../hooks";
import Text from "antd/lib/typography/Text";
import { NavLink, useLocation } from "react-router-dom";
import { dashboardRoutes } from "../../routers";
import { logoHorizontal } from "../../assets";

import "../../styles/DashboardLayout.less";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const rootSubmenuKeys = [""];

const DashboardLayout = ({ children: PanelContent }) => {
  const [state, setState] = React.useState({
    collapsed: false,
  });
  const [openKeys, setOpenKeys] = React.useState([""]);
  const [selectedKey, setSelectedKey] = React.useState("");
  const location = useLocation();
  const { user, userLoading, signOut } = useAuth();

  React.useEffect(() => {
    const flatter = (r) =>
      r?.routes
        ? [
            r,
            ...r?.routes
              .map((sub) => ({ ...sub, path: r.path + sub.path }))
              .flatMap(flatter),
          ]
        : r;
    const flatted = dashboardRoutes.flatMap(flatter);
    const paths = flatted.map((r) => r.path);
    const key = paths.find((path) => path === location.pathname);
    // TODO: Hay algo extraño con las rutas base, revisar
    // const sub = key.substr(0, key.lastIndexOf("/"));
    // if (paths.some((p) => p.includes(sub))) {
      setSelectedKey(key);
    // }
    const tmpOpenKeys = flatted
      .filter(
        (r) => r.sidebar === "collapse" && location.pathname.includes(r.path)
      )
      .map((r) => "collapse-" + r.path);
    setOpenKeys(tmpOpenKeys);
  }, [location]);

  const onCollapse = (collapsed) => {
    setState({ collapsed });
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const sidebarMapper = (route) => {
    if (route.sidebar === "single") {
      return (
        <Menu.Item
          key={route.path}
          onClick={() => setSelectedKey(route.path)}
          icon={route.icon}
        >
          <NavLink to={route.path}>{route.name}</NavLink>
        </Menu.Item>
      );
    } else if (route.sidebar === "collapse") {
      const innerMap = (r) => ({ ...r, path: route.path + r.path });
      const finalKey = "collapse-" + route.path;
      return (
        <SubMenu key={finalKey} icon={route.icon} title={route.name}>
          {route?.routes?.map(innerMap).map(sidebarMapper)}
        </SubMenu>
      );
    }
    return null;
  };

  const { collapsed } = state;

  if (!user && userLoading) return null;

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        id="auth-sider"
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        theme="light"
      >
        <div style={{display: 'flex', flexDirection: 'column', alignItems:'center'}}>
          <img src={logoHorizontal} className="logo"  alt="sutemmh-logo"  />
        </div>
        {/* <Button
          type="primary"
          block
          style={{ height: 64 }}
          icon={<HomeOutlined />}
        >
          AntDesign
        </Button> */}

        <Menu
          mode="inline"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          className="sider-menu"
          selectedKeys={selectedKey}
          inlineIndent={18}
        >
          {dashboardRoutes.map(sidebarMapper)}
          <Menu.Item
            key="single-/0"
            icon={<LogoutOutlined />}
            onClick={signOut}
          >
            Cerrar sesión
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{ padding: "0px 10px" }}
        >
          <Row justify="space-between">
            <Col>
              <Text strong>Sindicato Único de Trabajadores de Empresas Maquiladoras del Municipio de Hermosillo</Text>
            </Col>
            <Col>
              <Row>
                <Col>{user?.email}</Col>
                <Col style={{ marginLeft: 10 }}>
                  <Avatar size={42} icon={<UserOutlined />} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Header>
        <Content style={{ margin: "0 16px" }}>
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>Inicio</Breadcrumb.Item>
          </Breadcrumb>
          {/* <div className="site-layout-background" style={{ padding: 24 }}> */}
          {PanelContent}
          {/* </div> */}
        </Content>
        <Footer style={{ textAlign: "center" }}>
          SUTEMMH 2021 - Hermosillo, Sonora
        </Footer>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
