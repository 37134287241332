import React from 'react'

const Users = () => {
  return (
    <div>
      Página de usuarios
    </div>
  )
}

export default Users
