import React from 'react'

const Loans = () => {
  return (
    <div>
      Loans view
    </div>
  )
}

export default Loans
