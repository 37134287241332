import {
  DesktopOutlined,
  DollarOutlined,
  FileProtectOutlined,
  TeamOutlined,
  SettingOutlined,
  UserOutlined,
  FileDoneOutlined
} from "@ant-design/icons";

import HomeView from "../views/home/Home";
import AccountView from "../views/home/Account";
import LoansView from "../views/home/Loans";
import LoanRequestsView from "../views/home/LoanRequests";
import EmployeesView from "../views/home/Employees";
import UsersView from '../views/admin/Users';
import LoanTypesView from '../views/admin/LoanTypes';
import NotAuthorizedView from "../views/shared/NotAuthorized";
import NotFoundView from "../views/shared/NotFound";

const sharedRoutes = [
  {
    path: "/no-autorizado",
    component: NotAuthorizedView,
  },
  {
    path: "*",
    component: NotFoundView,
  },
];

const dashboardRoutes = [
  {
    layout: "dashboard",
    path: "/",
    name: "Inicio",
    icon: <DesktopOutlined />,
    sidebar: "single",
    component: HomeView,
  },
  {
    layout: "dashboard",
    path: "/cuenta",
    name: "Mi cuenta",
    icon: <UserOutlined />,
    sidebar: "single",
    component: AccountView,
  },
  {
    layout: "dashboard",
    path: "/prestamos",
    name: "Préstamos",
    icon: <DollarOutlined />,
    sidebar: "single",
    component: LoansView,
  },
  {
    layout: "dashboard",
    path: "/solicitudes",
    name: "Solicitudes",
    icon: <FileProtectOutlined />,
    sidebar: "single",
    component: LoanRequestsView,
  },
  {
    layout: "dashboard",
    path: "/empleados",
    name: "Empleados",
    icon: <TeamOutlined />,
    sidebar: "single",
    component: EmployeesView,
  },
  {
    layout: "dashboard",
    path: "/admin",
    name: "Administración",
    icon: <SettingOutlined />,
    sidebar: "collapse",
    routes: [
      {
        path: "/usuarios",
        name: "Usuarios",
        icon: <UserOutlined />,
        sidebar: "single",
        component: UsersView,
      },
      {
        path: "/tipo-prestamo",
        name: "Tipos de préstamo",
        icon: <FileDoneOutlined />,
        sidebar: "single",
        component: LoanTypesView,
      },
    ],
  },
  ...sharedRoutes,
];

const publicRoutes = [...sharedRoutes];

export { dashboardRoutes, publicRoutes };
