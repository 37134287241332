import React from 'react'

const LoanRequests = () => {
  return (
    <div>
      Solicitud de prestamos
    </div>
  )
}

export default LoanRequests
