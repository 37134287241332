import React from 'react'

const Employees = () => {
  return (
    <div>
      Vista de empleados
    </div>
  )
}

export default Employees
