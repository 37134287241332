import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";

const {
  REACT_APP_FB_API_KEY: apiKey,
  REACT_APP_FB_AUTH_DOMAIN: authDomain,
  REACT_APP_FB_PROJ_ID: projectId,
  REACT_APP_FB_STORAGE: storageBucket,
  REACT_APP_FB_SENDER_ID: messagingSenderId,
  REACT_APP_FB_APP_ID: appId,
} = process.env;

const config = {
  apiKey,
  authDomain,
  projectId,
  storageBucket,
  messagingSenderId,
  appId,
};

firebase.initializeApp(config);

const auth = firebase.auth();
const firestore = firebase.firestore();

export { auth, firestore };
export default firebase;
