import React from "react";
import { Form, Input, Button, Checkbox, Row, Col } from "antd";
import { useAuth } from "../../hooks";
import Title from "antd/lib/typography/Title";

import { logo473x } from "../../assets";
import { UserOutlined } from "@ant-design/icons";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const SignIn = () => {
  const { signIn, sessionLoading } = useAuth();

  const onFinish = (values) => {
    const { correo, clave } = values;
    signIn(correo, clave);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Row>
      <Col span={24} style={{ textAlign: "center", marginBottom: 25 }}>
        {/* <UserOutlined style={{ fontSize: 42 }} /> */}
        <img src={logo473x} alt="sutemmh" width={200} />

        <Title level={4}>Es necesario <span color="warning">iniciar sesión</span> para continuar</Title>
      </Col>
      <Col span={12} offset={4}>
        <Form
          {...layout}
          name="basic"
          initialValues={{
            email: "",
            password: "",
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Correo electrónico"
            name="correo"
            rules={[
              {
                required: true,
                message: "Porfavor ingrese su correo electrónico",
              },
            ]}
          >
            <Input type="email" />
          </Form.Item>

          <Form.Item
            label="Contraseña"
            name="clave"
            rules={[
              {
                required: true,
                message: "Porfavor ingrese su contraseña!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item {...tailLayout} name="remember" valuePropName="checked">
            <Checkbox>Recordar sesión?</Checkbox>
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" loading={sessionLoading}>
              Iniciar sesión
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default SignIn;
