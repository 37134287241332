import { Col, Layout, Row } from "antd";
import { Content, Footer, Header } from "antd/lib/layout/layout";
import Text from "antd/lib/typography/Text";
import React from "react";
import "../../styles/AuthLayout.less";

const AuthLayout = ({ children }) => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header>
        <Row justify="space-between">
          <Col>
            <Text strong>SUTEMMH</Text>
          </Col>
          <Col>
            <Text>Inicio de sesión</Text>
          </Col>
        </Row>
      </Header>
      <Content style={{ margin: 32 }}>{children}</Content>
      <Footer>SUTEMMH 2021 - Hermosillo, Sonora</Footer>
    </Layout>
  );
};

export default AuthLayout;
