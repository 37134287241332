import React from "react";
import { auth } from "../services/firebase";

import { useAuthState } from "react-firebase-hooks/auth";
import { emptyRequest, getRequest } from "../constants/requests";
import { useHttp } from "./useHttp";
import { useApp } from "./useApp";

const AuthContext = React.createContext();

const empty = emptyRequest();

export function AuthProvider(props) {
  const { token, setToken } = useApp();
  const [session, sessionLoading, sessionError] = useAuthState(auth);
  const [profileRequest, setProfileRequest] = React.useState(empty);
  const [profileResponse, profileResponseLoading, profileError] =
    useHttp(profileRequest);

  const onTokenChange = React.useCallback(
    (userCredential) => {
      // console.log("useAuth: [React.useCallback] onTokenChange");
      if (userCredential) {
        userCredential.getIdToken().then((newToken) => {
          setToken(newToken);
        });
      }
    },
    [setToken]
  );

  const signIn = React.useCallback(async (email, password) => {
    try {
      await auth.signInWithEmailAndPassword(email, password);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const signOut = React.useCallback(async () => {
    try {
      await auth.signOut();
    } catch (e) {
      console.error(e);
    }
  }, []);

  React.useEffect(() => {
    let mounted = true;
    let unsub = null;
    if (mounted && session) {
      unsub = auth.onIdTokenChanged(onTokenChange);
      // auth.currentUser.getIdTokenResult(true).then(onTokenChange);
    }
    return () => {
      mounted = false;
      if (unsub) unsub();
    };
  }, [session, onTokenChange]);

  React.useEffect(() => {
    if (token) {
      const agendaReq = getRequest("agenda/cuenta", {
        expand: "permiso,agenda,consejoElectoral,consejosExtra",
      });
      // agendaReq.alert = true;
      setProfileRequest(() => agendaReq);
    } else {
      setProfileRequest(empty);
    }
  }, [token]);

  const memData = React.useMemo(() => {
    let userTmp = null;
    if (session?.email)
      userTmp = { email: session.email }
    if (profileResponse?.resultado?.length)
      userTmp = { ...profileResponse.resultado[0] };
    if (profileResponse?.detalle)
      userTmp = { ...userTmp, ...profileResponse.detalle };

    return {
      session,
      sessionLoading,
      user: userTmp,
      userLoading: profileResponseLoading,
      userError: profileError || sessionError,
      signIn,
      signOut,
    };
  }, [
    session,
    sessionLoading,
    sessionError,
    profileResponse,
    profileResponseLoading,
    profileError,
    signIn,
    signOut,
  ]);

  return <AuthContext.Provider value={memData} {...props} />;
}

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (!context) {
    // eslint-disable-next-line no-throw-literal
    throw "error: auth context not defined.";
  }
  return context;
}
