import React from "react";
import { useAlert, useAuth } from "../hooks";
import PrivateRouter from "./PrivateRouter";
import PublicRouter from "./PublicRouter";
import { Alert } from "antd";

const AppRouting = () => {
  const { session, sessionLoading, userLoading } = useAuth();
  const { open, severity, message } = useAlert();

  const AlertMemo = React.useMemo(
    () => (
      open ? <Alert type={severity} message={message} closable showIcon /> : null
    ),
    [open, severity, message]
  );

  console.log(sessionLoading, userLoading)

  if (sessionLoading || userLoading) return null;

  return (
    <>
      {session ? <PrivateRouter /> : <PublicRouter />}
      {AlertMemo}
    </>
  );
};

export default AppRouting;