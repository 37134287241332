import React from 'react'

const LoanTypes = () => {
  return (
    <div>
      Tipos de prestamo
    </div>
  )
}

export default LoanTypes
